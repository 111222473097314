
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import DataView from 'primevue/dataview'
import PanelMenu from 'primevue/panelmenu'
import Paginator from 'primevue/paginator'
import { lCustomer, lCtmCat } from '@/api/customer'
import { lDatasource } from '@/api/datasource'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'Customer',
  setup () {
    const { t } = useI18n()
    return { t }
  },
  components: { Button, DataView, PanelMenu, Paginator },
  data () {
    return {
      lQuery: {
        page: 1,
        lg: 'vi',
        code: 'customer',
        slug: ''
      },
      catgroup: { ldata: { name: '' } },
      layout: 'list',
      totalItemsCount: 0,
      opct: {},
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      lcustomers: [],
      mnitems: []
    }
  },
  created () {
    const cmslug: any = this.$route.params.id ? this.$route.params.id : 0
    const cmlg: any = this.$route.params.lg ? this.$route.params.lg : 'vi'
    this.lQuery.lg = cmlg
    this.lQuery.slug = cmslug
    this.gList()
  },
  methods: {
    gList () {
      this.glcustomers()
      this.glmenus()
    },
    glcustomers () {
      lCustomer(this.lQuery).then((res) => {
        if (res) {
          var tmp: any = []
          console.log(res)
          res.data.rdata.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.ldata.company, url: '/' + this.lQuery.lg + '/khach-hang/' + item.catslug + '-' + item.ldata.cat_id + '/' + item.ldata.slug + '-' + item.code, image: '/img/kpibsc-logo.png' })
          })
          this.totalItemsCount = res.data.rdata.total
          this.lcustomers = tmp
          if (res.data.cdata) {
            this.catgroup = res.data.cdata
          }
        }
        this.$emit('loadfooter')
      })
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    glmenus () {
      lDatasource({ code: 'customergroup' }).then((res) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            var title = item.ldata.name
            if (this.lQuery.lg === 'en' && item.ldata.titleen !== undefined) {
              title = item.ldata.titleen
            }
            tmp.push({ key: item.id, title: title, url: '/' + this.lQuery.lg + '/khach-hang/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    }
  }
})
