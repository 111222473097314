
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import DataView from 'primevue/dataview'
import PanelMenu from 'primevue/panelmenu'
import { lCustomer, lCtmCat, ldCustomer } from '@/api/customer'
import { lDatasource } from '@/api/datasource'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  name: 'CTMdetail',
  components: { Button, DataView, PanelMenu },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      lQuery: {
        page: 'customer',
        lg: 'vi',
        except: '',
        slug: ''
      },
      opct: {},
      totalItemsCount: 0,
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const lg = this.$route.params.lg ? this.$route.params.lg : 'vi'
    const dmslug = this.$route.params.dm ? this.$route.params.dm : 0
    const cmslug = this.$route.params.id ? this.$route.params.id : ''
    var slug: any = dmslug
    this.lQuery.slug = slug
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      ldCustomer({ slug: cmslug }).then((res) => {
        if (res) {
          var tmp: any = {}
          var item: any = res.data.rdata.ldata
          tmp = { key: res.data.rdata.id, title: item.company, url: '/' + this.lQuery.lg + '/khach-hang/' + res.data.rdata.catslug + '-' + item.cat_id + '/' + item.slug + '-' + item.code, image: '/img/kpibsc-logo.png' }
          this.ldata = tmp
          this.lQuery.except = res.data.rdata.code
          this.glcustomers()
        }
      })
      this.glmenus()
      this.$emit('loadfooter')
    },
    glcustomers () {
      lCustomer(this.lQuery).then((res) => {
        if (res) {
          var tmp: any = []
          console.log(res)
          res.data.rdata.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.ldata.company, url: '/' + this.lQuery.lg + '/khach-hang/' + item.catslug + '-' + item.ldata.cat_id + '/' + item.ldata.slug + '-' + item.code, image: '/img/kpibsc-logo.png' })
          })
          this.totalItemsCount = res.data.rdata.total
          this.lsdatas = tmp
        }
      })
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    glmenus () {
      lDatasource({ code: 'customergroup' }).then((res) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            var title = item.ldata.name
            if (this.lQuery.lg === 'en' && item.ldata.titleen !== undefined) {
              title = item.ldata.titleen
            }
            tmp.push({ key: item.id, title: title, url: '/' + this.lQuery.lg + '/khach-hang/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    }
  }
})
