
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputNumber from 'primevue/inputnumber'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import PanelMenu from 'primevue/panelmenu'
import Breadcrumb from 'primevue/breadcrumb'
import { gProduct } from '@/api/product'
import { lDatasource } from '@/api/datasource'
@Options({
  components: { Section, Button, DataTable, Column, InputNumber, DataView, DataViewLayoutOptions, Dropdown, PanelMenu, Breadcrumb },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lang: 'vi'
      },
      quantity: 1,
      columns: [],
      pricegroup: [],
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      gProduct({ slug: cmslug, lg: 'vi' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          this.ldata = res.data.data
          this.url = res.data.url
          if (res.data.samedata && res.data.samedata.length > 0) {
            res.data.samedata.forEach((item: any) => {
              tmp.push({ key: item.id, title: item.title, url: '/bai-viet/' + item.slug + '-' + item.code, image: res.data.url + '/' + item.image })
            })
            this.lsdatas = tmp
          }
          if (res.data.cat) {
            this.breadcrumbs.push({ label: res.data.cat.title, to: '/' + res.data.cat.slug + '-' + res.data.cat.code })
          }
          if (res.data.pricegroup) {
            var tmpcol: any = [{ field: 'dv', header: '' }]
            var tmprow: any = { dv: '1 User/ Tháng' }
            var formatter = new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND'
            })
            res.data.pricegroup.forEach((item: any) => {
              tmpcol.push({ field: item.code, header: item.ldata.title + '<br/>( ' + item.ldata.minuser + ' - ' + item.ldata.maxuser + ' )' })
              tmprow[item.code] = formatter.format(item.ldata.price)
            })
            this.columns = tmpcol
            this.pricegroup.push(tmprow)
          }
        }
        this.$emit('loadfooter')
      })
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat', lg: 'vi' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.ldata.title, url: '/san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    }
  }
})
export default class Home extends Vue {}
