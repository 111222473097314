
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputNumber from 'primevue/inputnumber'
import PanelMenu from 'primevue/panelmenu'
import Breadcrumb from 'primevue/breadcrumb'
import { gProduct } from '@/api/product'
import { lDatasource } from '@/api/datasource'
import { useI18n } from 'vue-i18n'
import sProduct from '@/components/sproduct.vue'
export default defineComponent({
  components: { Button, DataTable, Column, InputNumber, PanelMenu, Breadcrumb, sProduct },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lang: 'vi'
      },
      slug: '',
      quantity: 10,
      columns: [],
      pricegroup: [{}],
      layout: 'list',
      lg: 'vi',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ldata: { code: '' },
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    var glg: any = sessionStorage.getItem('st-lg')
    this.lg = glg
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    if (this.$route.query.m && this.$route.query.kmcode) {
      var email: any = this.$route.query.m
      var kmcode: any = this.$route.query.kmcode
      sessionStorage.setItem('st-email', email)
      sessionStorage.setItem('st-kmcode', kmcode)
    }
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      gProduct({ slug: cmslug, lg: this.lg }).then((res: any) => {
        if (res) {
          var tmp: any = []
          this.ldata = res.data.data
          this.url = res.data.url
          if (res.data.samedata && res.data.samedata.length > 0) {
            res.data.samedata.forEach((item: any) => {
              tmp.push({ key: item.id, title: item.title, url: '/bai-viet/' + item.slug + '-' + item.code, image: res.data.url + '/' + item.image })
            })
            this.lsdatas = tmp
          }
          this.breadcrumbs = []
          if (res.data.cat) {
            this.slug = res.data.cat.ldata.slug + '-' + res.data.cat.code
            var title = res.data.cat.ldata.title
            if (this.lg === 'en' && res.data.cat.ldata.titleen) {
              title = res.data.cat.ldata.titleen
            }
            this.breadcrumbs.push({ label: title, to: '/' + this.slug })
          }
          if (res.data.pricegroup) {
            var tmpcol: any = [{ field: 'dv', header: '' }]
            var tmprow: any = { dv: '1 User/ Tháng' }
            var formatter = new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND'
            })
            res.data.pricegroup.forEach((item: any) => {
              tmpcol.push({ field: item.code, header: item.title + '<br/>( ' + item.minuser + ' - ' + item.maxuser + ' )' })
              tmprow[item.code] = formatter.format(item.price)
            })
            this.columns = tmpcol
            this.pricegroup = []
            this.pricegroup.push(tmprow)
          }
        }
        this.$emit('loadfooter')
      })
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            var title = item.ldata.title
            if (this.lg === 'en' && item.ldata.titleen) {
              title = item.ldata.titleen
            }
            tmp.push({ key: item.id, title: title, url: '/danh-muc-san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    boscart () {
      var dtvl = { stype: 0, pdcode: this.ldata.code, nuser: this.quantity }
      this.openshopcart(dtvl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    }
  }
})
