
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import PanelMenu from 'primevue/panelmenu'
import Breadcrumb from 'primevue/breadcrumb'
import Paginator from 'primevue/paginator'
import { lBlog, lBvCat } from '@/api/blog'
import { gArticle } from '@/api/article'
@Options({
  components: { Section, Button, DataView, DataViewLayoutOptions, Dropdown, PanelMenu, Breadcrumb, Paginator },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Blog', to: '/blog' }
      ],
      url: '',
      lQuery: {
        page: 1,
        lang: 'vi',
        limit: '5',
        slug: ''
      },
      opct: {},
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      totalItemsCount: 0,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    let cmlg = this.$route.params.lg ? this.$route.params.lg : ''
    if (cmlg !== '') {
      cmlg = sessionStorage.getItem('st-lg')
    }
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      gArticle({ slug: cmslug }).then((res: any) => {
        if (res) {
          var tmp: any = []
          this.ldata = res.data.data
          this.url = res.data.url
          if (res.data.cat) {
            this.breadcrumbs.push({ label: res.data.cat.title, to: '/' + this.lQuery.lang + '/blog/danh-muc/' + res.data.cat.slug + '-' + res.data.cat.code })
            this.lQuery.slug = res.data.cat.slug + '-' + res.data.cat.code
            this.glbaiviets()
          }
        }
        this.$emit('loadfooter')
      })
      this.glmenus()
    },
    glbaiviets () {
      lBlog(this.lQuery).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.data.data.forEach((item: any) => {
            tmp.push({
              key: item.id,
              title: item.title,
              nview: item.vcount,
              shortdesc: item.short_desc,
              url: '/' + this.lQuery.lang + '/blog/bai-viet/' + item.slug + '-' + item.code,
              image: res.data.url + '/' + item.image,
              cattitle: item.cattitle,
              created_at: item.acreated_at,
              caturl: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.catslug + '-' + item.cat_id
            })
          })
          this.lsdatas = tmp
          this.totalItemsCount = res.data.data.total
        }
      })
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    glmenus () {
      lBvCat({}).then((res: any) => {
        if (res) {
          var tmp: any = []
          console.log(res)
          res.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.title, url: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    onPage (event: any) {
      this.lQuery.page = parseInt(event.page) + 1
      this.glbaiviets()
    }
  }
})
export default class Home extends Vue {}
