import request from '@/utils/request'

/**
 * duyetvbd
 */

// Danh sach tat ca cac bai viet
export function gArticle (query: any) {
  return request({
    url: '/lgatcsasdf',
    method: 'get',
    params: query
  })
}
// Danh sach danh muc bai viet
export function lBvCat (query: any) {
  return request({
    url: '/lctsdfss',
    method: 'get',
    params: query
  })
}
