import request from '@/utils/request'

/**
 * duyetvbd
 */

// Danh sach tat ca cac khach hang
export function lCustomer (query: any) {
  return request({
    url: '/lctmsdfseee',
    method: 'get',
    params: query
  })
}
// Danh sach danh muc khach hang
export function lCtmCat (query: any) {
  return request({
    url: '/ldmctmsdfss',
    method: 'get',
    params: query
  })
}

// thong tin khach hang
export function ldCustomer (query: any) {
  return request({
    url: '/ldctmadwttds',
    method: 'get',
    params: query
  })
}
