<template>
  <div>
    <Dialog v-model:visible="vs" modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :showHeader="false">
    <div class="col-12 md:col-6 flex align-items-center justify-content-end md:pr-4">
      <span class="p-input-icon-right right-0">
      <i class="pi pi-search" @click="tgsearch"/>
      <InputText type="text" v-model="searchtext" placeholder="Search" v-on:keyup.enter="gList" />
      </span>
    </div>
    <br />
    <div>
    <Listbox v-model="slSearch" optionValue="url" @change="goLink" :options="lpds" listStyle="max-height:450px" style="width:100%">
    <template #option="slotProps">
    <div>
      <span>{{slotProps.option.title}}</span>
      <span v-if="slotProps.option.shorttitle"> ({{slotProps.option.shorttitle}})</span>
      </div>
      <div v-if="slotProps.option.shortdesc" v-html="slotProps.option.shortdesc"></div>
    </template>
</Listbox>
    </div>
    <template #footer>
      <Button label="Close" @click="close"/>
    </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Listbox from 'primevue/listbox'
import { search } from '@/api/search'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { Dialog, Button, InputText, Listbox },
  props: {
    vs: {
      type: Boolean,
      default: false
    },
    stext: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      slSearch: '',
      lpds: [],
      slug: '',
      searchtext: '',
      url: ''
    }
  },
  watch: {
    stext: function (newvl, oldvl) {
      this.searchtext = newvl
    },
    vs: function (newvl, oldvl) {
      if (newvl) {
        this.gList()
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.gList()
    })
  },
  computed: {
    visible: {
      get () {
        return this.vs
      },
      set () {
        this.close()
      }
    }
  },
  methods: {
    async gList () {
      const sendData = { slug: this.slug, lang: 'vi', sw: this.searchtext }
      await this.$store.dispatch('product/l', sendData).then((res) => {
        if (res) {
          this.lpds = res.data.data.data
          this.lpds.forEach((li) => {
            li.url = '/san-pham/' + li.slug + '-' + li.code
          })
          this.url = res.data.url
        }
      })
    },
    goLink () {
      if (this.slSearch) {
        this.$router.replace(this.slSearch)
      }
    },
    close () {
      this.$emit('close')
    }
  }
})
export default class Searchbox extends Vue {}
</script>
