
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import home1 from '@/components/json/home1.json' // @ is an alias to /src
import { ctntrack } from '@/api/tracking'
import { glSection } from '@/api/section'
@Options({
  components: {
    Section
  },
  data () {
    return {
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }],
      lQuery: {
        page: 'home',
        lang: 'vi',
        sscode: []
      },
      gstart: 0,
      lsdata: [],
      lsections: []
    }
  },
  created () {
    this.lQuery.lang = sessionStorage.getItem('st-lg')
    this.gList()
  },
  watch: {
    $route: function (newvl: any, oldvl: any) {
      var gpath = newvl.path.split('/')
      this.lQuery.lang = sessionStorage.getItem('st-lg')
      this.gstart = 0
      this.lsdata = []
    }
  },
  methods: {
    gList () {
      this.$emit('loadfooter')
    },
    utracking (vl: any) {
      var gss = sessionStorage.getItem('st-acpage')
      var to = this.$route
      var gtdata = { rsession: gss, pageid: to.path, pagetitle: to.meta.title, elementid: vl.eid }
      ctntrack(gtdata)
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    }
  }
})
export default class Notfound extends Vue {}
