
import { ref, reactive, defineComponent } from 'vue'
import PanelMenu from 'primevue/panelmenu'
import { gProduct, lProduct } from '@/api/product'
import { lDatasource } from '@/api/datasource'
import sProduct from '@/components/sproduct.vue'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { PanelMenu, sProduct },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbs: [
        { label: 'Sản phẩm', to: '/san-pham' }
      ],
      url: '',
      lQuery: {
        page: 'customer',
        lang: 'vi'
      },
      quantity: 1,
      columns: [],
      pricegroup: [],
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      slug: '',
      ldata: {},
      lsdatas: [],
      mnitems: []
    }
  },
  created () {
    const cmslug = this.$route.params.id ? this.$route.params.id : 0
    this.gList(cmslug)
  },
  methods: {
    gList (cmslug: any) {
      this.slug = cmslug
      this.glmenus()
    },
    glmenus () {
      lDatasource({ code: 'productcat', lg: 'vi' }).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.rdata.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.ldata.title, url: '/danh-muc-san-pham/' + item.ldata.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
        this.$emit('loadfooter')
      })
    }
  }
})
