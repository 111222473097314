<template>
  <div class="flex justify-content-center mb-4">
  <div class="w-full md:w-6">
  <div><h3>Thông tin của bạn.</h3></div>
  <div class="field">
  <div class="p-float-label mt-4">
  <InputText id="uname" v-model="sender.name" class="w-full"/>
  <label for="uname">Họ tên</label>
  </div>
  </div>
  <div class="field">
  <div class="p-float-label mt-4">
  <InputText id="uemail" v-model="sender.email"  class="w-full"/>
  <label for="uemail">Email</label>
  </div>
  </div>
  <div><h3>Vui lòng chia sẻ với bạn bè để nhận chiết khấu cho lần đặt hàng tiếp theo.</h3></div>
  <SendSharingF @Sendsharing="Sendsharing" :sender="sender" />
  </div>
  </div>
</template>
<script>
import InputText from 'primevue/inputtext'
import SendSharingF from '@/components/sendsharing'
import { Options, Vue } from 'vue-class-component'
@Options({
  components: { InputText, SendSharingF },
  props: {
    gsender: {
      type: Object
    }
  },
  data () {
    return {
      visible: false,
      slSearch: '',
      sender: { name: '', email: '' },
      url: ''
    }
  },
  created () {
    // this.sender = this.gsender
    this.sender.name = sessionStorage.getItem('st-name')
    this.sender.email = sessionStorage.getItem('st-email')
    this.$emit('loadfooter')
  },
  watch: {
    gsender: function (newvl, oldvl) {
      this.sender = newvl
    }
  },
  methods: {
    Sendsharing (vl) {
      if (this.slSearch) {
        this.$router.replace(this.slSearch)
      }
    },
    close () {
      this.$emit('close')
    }
  }
})
export default class Searchbox extends Vue {}
</script>
