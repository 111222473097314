
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import PanelMenu from 'primevue/panelmenu'
import { lDiscount } from '@/api/datasource'
@Options({
  components: { Section, Button, DataView, DataViewLayoutOptions, Dropdown, PanelMenu },
  data () {
    return {
      lQuery: {
        page: 'flashsale',
        lang: 'vi'
      },
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      timerCount: 10,
      lflashsales: [],
      glvl: {},
      mnitems: []
    }
  },
  created () {
    this.gList()
  },
  watch: {
    timerCount: {
      handler (value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true
    }
  },
  methods: {
    gList () {
      this.glflashsales()
      this.$emit('loadfooter')
    },
    countdowndata (stdate: any, gdate: any, gdiv: any) {
      var countDownDate = gdate.getTime()
      var startDate = stdate.getTime()
      const vm = this
      // Update the count down every 1 second
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()
        // Find the distance between now and the count down date
        var distance = countDownDate - now
        var begindistance = now - startDate
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)
        // Output the result in an element with id="demo"
        vm.glvl[gdiv] = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
        // If the count down is over, write some text
        if (begindistance < 0) {
          clearInterval(x)
          vm.glvl[gdiv] = 'Sắp có ...'
        }
        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(x)
          vm.glvl[gdiv] = 'Đã quá hạn'
        }
      }, 1000)
    },
    openshopcart (vl: any) {
      var dtvl = { pageid: 'flashsale', kmcode: vl, stype: 1 }
      this.$emit('openshopcart', dtvl)
    },
    glflashsales () {
      lDiscount({ lg: 'vi' }).then((res: any) => {
        if (res.data.code === 200) {
          this.lflashsales = res.data.data
          this.lflashsales.forEach((lfs: any) => {
            var kmdata = lfs.ldata
            var startdate = new Date()
            var starttmpdate = new Date()
            var endtmpdate = new Date()
            var startkmdate = new Date(kmdata.startdate + ' ' + kmdata.starttime)
            var endkmdate = new Date(kmdata.startdate + ' ' + kmdata.endtime)
            endkmdate.setDate(endkmdate.getDate() + parseInt(kmdata.tlhieuluc))
            if (kmdata.kieuthoiluong !== '920220326083928') {
              var tmpsa = kmdata.starttime.split(':')
              var tmpfa = kmdata.endtime.split(':')
              starttmpdate.setHours(tmpsa[0], tmpsa[1], 0)
              startkmdate = starttmpdate
              endtmpdate.setHours(tmpfa[0], tmpfa[1], 0)
              endkmdate = endtmpdate
            }
            console.log(startkmdate)
            console.log(endkmdate)
            this.countdowndata(startkmdate, endkmdate, kmdata.kmcode)
          })
        }
      })
    }
  }
})
export default class Flashsale extends Vue {}
