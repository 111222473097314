
import { ref, reactive, defineComponent } from 'vue'
import { lBlog, lBvCat } from '@/api/blog' // @ is an alias to /src
import DataView from 'primevue/dataview'
import PanelMenu from 'primevue/panelmenu'
import Paginator from 'primevue/paginator'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { DataView, PanelMenu, Paginator },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      lQuery: {
        page: 1,
        limit: 15,
        lang: 'vi'
      },
      totalItemsCount: 0,
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      lbaiviets: [],
      mnitems: []
    }
  },
  created () {
    var lang: any = sessionStorage.getItem('st-lg')
    this.lQuery.lang = lang
    this.gList()
  },
  methods: {
    gList () {
      this.glbaiviets()
      this.glmenus()
    },
    glbaiviets () {
      lBlog(this.lQuery).then((res: any) => {
        if (res) {
          var tmp: any = []
          res.data.data.data.forEach((item: any) => {
            tmp.push({
              key: item.id,
              title: item.title,
              nview: item.vcount,
              shortdesc: item.short_desc,
              url: '/' + this.lQuery.lang + '/blog/bai-viet/' + item.slug + '-' + item.code,
              image: res.data.url + '/' + item.image,
              cattitle: item.cattitle,
              created_at: item.acreated_at,
              caturl: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.catslug + '-' + item.cat_id
            })
          })
          this.totalItemsCount = res.data.data.total
          this.lbaiviets = tmp
        }
        this.$emit('loadfooter')
      })
    },
    glmenus () {
      lBvCat({ lang: this.lQuery.lang }).then((res: any) => {
        if (res) {
          var tmp: any = []
          console.log(res)
          res.data.forEach((item: any) => {
            tmp.push({ key: item.id, title: item.title, url: '/' + this.lQuery.lang + '/blog/danh-muc/' + item.slug + '-' + item.code })
          })
          this.mnitems = tmp
        }
      })
    },
    onPage (event: any) {
      this.lQuery.page = parseInt(event.page) + 1
      this.glbaiviets()
    }
  }
})
